const commodityColumns = [
  {
    title: '商品名称',
    key: 'name',
    sortable: true,
    minWidth: '190',
    width: '170'
  },
  {
    title: '宣传文案',
    key: 'description',
    minWidth: '200',
    width: '280'
  },
  {
    title: '原价',
    key: 'price',
    align: 'right',
    sortable: true
    // minWidth: '110',
    // width: '140'
  },
  {
    title: '优惠价',
    key: 'discountedPrice',
    align: 'right',
    sortable: true
    // minWidth: '110',
    // width: '140'
  },
  {
    title: '课时',
    key: 'period',
    align: 'right',
    sortable: true
    // width: '140'
  },
  {
    title: '销售量',
    key: 'sales',
    align: 'right',
    sortable: true
    // minWidth: '110',
    // width: '140'
  },
  {
    title: '创建时间',
    key: 'createTime',
    align: 'left',
    sortable: true,
    width: '170'
  },
  {
    title: '有效期',
    key: 'timeLimit',
    sortable: true
    // width: '140'
  },
  {
    title: '状态',
    key: 'state',
    sortable: true,
    fixed: 'right',
    width: '100'
  }
]
const orderColumns = [
  {
    title: '头像',
    key: 'img',
    width: '90'
  },
  {
    title: '用户名',
    key: 'userName',
    width: '130'
  },
  {
    title: '订单编号',
    key: 'orderNum',
    width: '175'
  },
  {
    title: '商品名称',
    key: 'name',
    width: ''
  },
  {
    title: '订单金额',
    key: 'orderPrice',
    sortable: true,
    align: 'right',
    width: '140'
  },
  {
    title: '有效期',
    key: 'validityTime',
    align: 'center',
    width: '120'
  },
  {
    title: '订单状态',
    key: 'status',
    align: 'right',
    sortable: true,
    width: '100'
  },
  {
    title: '订单时间',
    key: 'orderTime',
    sortable: true,
    width: '175'
  }
]
const basicInfo = [
  {
    name: '课程名称',
    key: 'courseName',
    value: null
  },
  {
    name: '商品名称',
    key: 'name',
    value: null
  },
  {
    name: '流量限制',
    key: 'flowLimit',
    value: null
  },
  {
    name: '状态',
    key: 'state',
    value: null
  },
  {
    name: '宣传文案',
    key: 'description',
    value: null
  }
]
const courseInfo = [
  {
    name: '课程名称',
    key: 'courseName',
    value: null
  },
  {
    name: '',
    key: 'url',
    value: null
  },
  {
    name: '总课时',
    key: 'period',
    value: null
  }
]
const commodityInfo = [
  {
    name: '收费方式',
    key: 'feeType',
    value: null
  },
  {
    name: '销售量',
    key: 'sales',
    value: 888
  },
  {
    name: '原价',
    key: 'price',
    value: null
  },
  {
    name: '优惠价',
    key: 'discountedPrice',
    value: null
  },
  {
    name: '创建时间',
    key: 'createTime',
    value: null
  },
  {
    name: '有效期',
    key: 'timeLimit',
    value: null
  },
  {
    name: '试看章节',
    key: 'freeChapterIds',
    value: []
  }
]
export { commodityColumns, orderColumns, basicInfo, courseInfo, commodityInfo }
