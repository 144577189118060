import config from '@/config/config'
import { axios } from './index'

const productUrl = config.baseUrl + '/course/api/v1/product'
export default {
  productList({ page, size, state, name }) {
    return axios.post(
      `${productUrl}/list?pageNumber=${page}&pageSize=${size}${state !== null ? `&state=${state}` : ''}${name ? `&name=${name}` : ''}`
    )
  },
  // 获取所有课程
  AllCourseList() {
    return axios.get(`${productUrl}/organization-course/list`)
  },
  // 获取该课程下的所有章节信息
  chapterList(courseId) {
    return axios.get(`${productUrl}/course/chapter/list?courseId=${courseId}`)
  },
  // 商品保存不发布
  commodityAppend(payload) {
    return axios.post(`${productUrl}/add`, payload)
  },
  // 发布
  releaseById(id) {
    return axios.post(`${productUrl}/release?productCourseId=${id}`)
  },
  // 商品下架
  offShelf(id) {
    return axios.post(`${productUrl}/off-shelf?productCourseId=${id}`)
  },
  // 商品删除
  delCommodity(id) {
    return axios.post(`${productUrl}/delete?productCourseId=${id}`)
  },
  // 商品批量删除
  delBatchCommodity(payload) {
    return axios.post(`${productUrl}/delete/batch`, payload)
  },
  // 获取商品详细信息  修改也需要
  commodityInfo(id) {
    return axios.get(`${productUrl}/info?productCourseId=${id}`)
  },
  // 商品编辑
  commodityModify(payload) {
    return axios.post(`${productUrl}/modify`, payload)
  },
  // 上架商品
  onShelf(id) {
    return axios.post(`${productUrl}/release?productCourseId=${id}`)
  }
}
