<template>
  <div class="commodity-main yt-flex-layout">
    <div class="bread-div yt-flex-layout">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="commodity-content">
      <div class="content-card">
        <div class="content-header yt-flex-layout">
          <div class="header-left">
            <el-button @click="toAdd()" type="primary" class="btn" size="small">
              <div>
                <JYIcon @click="toAdd()" :href="'#icon-xinzeng'" />
                <p>新建</p>
              </div>
            </el-button>
            <el-tooltip placement="top" content="会自动过滤已上架或已删除的商品" :disabled="selectionList.length <= 0" effect="light">
              <el-button @click="delBatch()" class="btn" size="small">
                <div>
                  <JYIcon @click="delBatch()" :href="'#icon-shanchu'" />
                  <p>批量删除</p>
                </div>
              </el-button>
            </el-tooltip>
          </div>
          <div class="header-right">
            <el-input class="el-common--style" v-model="searchData.name" placeholder="请输入商品名称" />
            <el-select v-model="searchData.state" class="el-common--style" placeholder="请选择状态">
              <el-option :value="0" label="未发布" />
              <el-option :value="1" label="已上架" />
              <el-option :value="2" label="已下架" />
              <el-option :value="9" label="已删除" />
            </el-select>
            <el-button
              @click="
                searchData.page = 1
                getList()
              "
              type="primary"
              class="btn1"
              size="small"
              >查询</el-button
            >
            <el-button @click="reset()" class="btn1" size="small">重置</el-button>
          </div>
        </div>
        <div class="content-table">
          <div class="el-table--style">
            <el-table class="yt-table" @selection-change="handleSelect" :data="commodityList" width="100%" height="100%">
              <el-table-column type="selection" width="60" align="center" />
              <el-table-column
                v-for="(c, index) in columns"
                :sortable="c.sortable"
                :align="c.align"
                :key="index"
                :fixed="c.fixed"
                :label="c.title"
                :width="c.width"
                :min-width="c.minWidth"
                :prop="c.key"
              >
                <template slot-scope="scope">
                  <div v-if="c.key === 'state'">
                    <span
                      class="status-span yt-flex-layout flexCenter"
                      :style="{
                        backgroundColor: bgColorList[scope.row.state],
                        color: fontColorList[scope.row.state]
                      }"
                      >{{ scope.row.state | getStatus }}</span
                    >
                  </div>
                  <div v-else-if="c.key === 'description'">
                    <el-popover :content="scope.row[c.key]" :disabled="!scope.row[c.key]" trigger="hover" :width="350">
                      <template #reference>
                        <div class="text-style">{{ scope.row[c.key] || '—' }}</div>
                      </template>
                    </el-popover>
                  </div>
                  <div v-else-if="c.key === 'timeLimit'">{{ scope.row[c.key] ? `购买后${scope.row[c.key]}天` : '该商品免费使用' }}</div>
                  <div v-else>
                    <div class="text-style" :style="c.key === 'createTime' ? 'text-align: left' : ''">
                      {{ c.key === 'createTime' ? $formatTime(scope.row[c.key], 'yyyy-MM-dd hh:mm:ss') : scope.row[c.key] || '—' }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="140" fixed="right">
                <template slot-scope="scope" v-if="scope.row.state !== 9">
                  <span class="tool-button" @click="toDetail('/commodity/list/detail', scope.row.id)">查看</span>
                  <span @click="release(scope.row.id)" v-if="scope.row.state === 0" class="tool-button">发布</span>
                  <span @click="delCommodity(scope.row.id)" v-if="scope.row.state === 1" class="tool-button">删除</span>
                  <span v-if="scope.row.state !== 1 && scope.row.state !== 0" class="tool-button" @click="updateCommodity(scope.row.id)">修改</span>
                  <el-dropdown placement="bottom-start" @command="handleClick($event, scope.row)">
                    <div class="tool-button">
                      更多
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="update" v-if="scope.row.state === 0">
                        <span style="white-space: nowrap">修改</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="del" v-if="scope.row.state !== 1">
                        <span style="white-space: nowrap">删除</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="onShelf" v-if="scope.row.state === 2">
                        <span style="white-space: nowrap">上架</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="offShelf" v-if="scope.row.state === 1">
                        <span style="white-space: nowrap">下架</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="el-page--style yt-flex-layout">
            <Page
              class="yt-page"
              :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
              :total="total"
              :current="searchData.page"
              :page-size-opts="[10, 20, 40, 100]"
              :page-size="searchData.size"
              show-elevator
              show-sizer
              show-total
              @on-change="changePage"
              @on-page-size-change="changeSize"
            />
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialog ref="cancelModal" :title="'警告'" @on-ok="batchCommodities()">
      <div style="width: 219px; margin: 0 auto;">
        <JYIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        <span style="margin-left: 10px">{{ delDesc }}</span>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import { commodityColumns } from '@components/manage/commodity/commodityColumns'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import productApi from '@api/product'
export default {
  name: 'CommodityList',
  components: { JYIcon, ConfirmDialog },
  data() {
    return {
      total: 0,
      bgColorList: ['#FFEEEE', '#EFFFF4', '#EDF4FF', '', '', '', '', '', '', '#ECECEC'],
      fontColorList: ['#FF5050', '#4CE47A', '#448bff', '', '', '', '', '', '', '#666666'],
      searchData: {
        page: 1,
        size: 10,
        name: null,
        state: null
      },
      isBatch: false, // false 单删
      selectRowDelId: null,
      delDesc: null, // 删除弹框描述
      commodityList: [],
      selectionList: [],
      columns: commodityColumns
    }
  },
  created() {
    this.getList()
  },
  filters: {
    getStatus(type) {
      switch (type) {
        case 0:
          return '未发布'
        case 1:
          return '已上架'
        case 2:
          return '已下架'
        case 9:
          return '已删除'
      }
    }
  },
  methods: {
    batchCommodities() {
      // 确认批量删除
      if (this.isBatch) {
        productApi.delBatchCommodity({ productCourseIds: this.selectionList }).then(res => {
          if (res.code === 0) this.$message.success('删除成功')
          this.getList()
        })
      } else {
        productApi.delCommodity(this.selectRowDelId).then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.getList()
          }
        })
      }
    },
    handleSelect(rows) {
      // 此方法会自动过滤已上架商品
      this.selectionList = []
      this.selectionList = rows.map(r => {
        if (r.state !== 1 && r.state !== 9) return r.id
      })
    },
    delBatch() {
      // 批量删除
      if (this.selectionList.length <= 0) return this.$message.warning('请选择需要删除的商品')
      this.isBatch = true // 批量
      this.delDesc = '确定删除选中的商品吗,请谨慎操作'
      this.$refs['cancelModal'].open()
    },
    offShelf(id) {
      //商品下架
      productApi.offShelf(id).then(res => {
        if (res.code === 0) {
          this.$message.success('下架成功')
          this.getList()
        }
      })
    },
    onShelf(id) {
      //商品上架
      productApi.onShelf(id).then(res => {
        if (res.code === 0) {
          this.$message.success('上架成功')
          this.getList()
        }
      })
    },
    release(id) {
      // 发布
      productApi.releaseById(id).then(res => {
        if (res.code === 0) {
          this.$message.success('发布成功')
          this.getList()
        }
      })
    },
    delCommodity(id) {
      // 商品删除
      this.isBatch = false // 单删
      this.selectRowDelId = id
      this.delDesc = '确定删除该商品吗,请谨慎操作'
      this.$refs['cancelModal'].open()
    },
    updateCommodity(id) {
      // 进入修改编辑页面
      this.$router.push({ path: '/commodity/list/add', query: { id: id } })
    },
    handleClick(value, row) {
      if (value === 'offShelf') {
        this.offShelf(row.id)
      }
      if (value === 'onShelf') {
        this.onShelf(row.id)
      }
      if (value === 'del') {
        this.delCommodity(row.id)
      }
      if (value === 'update') {
        this.updateCommodity(row.id)
      }
    },
    toDetail(route, rowId) {
      // 查看详情页
      this.$router.push({ path: route, query: { id: rowId } })
    },
    reset() {
      // 重置
      this.searchData = {
        page: 1,
        size: 10,
        name: null,
        state: null
      }
      this.getList()
    },
    toAdd() {
      // 添加商品
      this.$router.push({ path: '/commodity/list/add', query: {} })
    },
    getList() {
      productApi.productList(this.searchData).then(res => {
        if (res.code === 0) {
          this.total = res.res.total
          this.commodityList = res.res.data
        }
      })
    },
    changePage(val) {
      this.searchData.page = val
      this.getList()
    },
    changeSize(val) {
      this.searchData.page = 1
      this.searchData.size = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.commodity-main {
  min-width: 1200px;
  background-color: #f8f8f8;
  flex-direction: column;
  height: 100%;
  .bread-div {
    align-items: center;
    padding: 0 12px;
    height: 44px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .commodity-content {
    height: calc(100% - 64px);
    padding: 0 20px;
    .content-card {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-radius: 6px;
      height: 100%;
    }
    .content-header {
      padding: 0 20px;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      .el-common--style {
        width: 200px;
        margin-right: 12px;
      }
      .btn1 {
        .wh(80px, 32px);
      }
      .btn {
        height: 32px;
      }
    }
    .content-table {
      height: calc(100% - 60px);
      .el-table--style {
        padding: 0 20px;
        height: calc(100% - 65px);
        .status-span {
          font-size: 12px;
          .wh(60px, 20px);
          border-radius: 10px;
        }
        .text-style {
          padding-right: 24px !important;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .el-page--style {
        align-items: center;
        padding: 0 30px;
        height: 52px;
        ::v-deep .yt-page {
          width: 100% !important;
        }
      }
    }
  }
}
.tool-button {
  cursor: pointer;
  color: #448bff;
  font-size: 12px;
  margin-right: 10px !important;
  &:hover {
    color: #07409d;
  }
}
::v-deep .el-popper {
  margin-top: 0 !important;
  padding: 10px !important;
}
::v-deep .el-dropdown {
  margin-left: 0 !important;
}
::v-deep .el-popover {
  padding: 0 20px !important;
}
::v-deep .el-dropdown-menu__item {
  font-size: 12px;
  color: #282c3d;
}
::v-deep .el-popconfirm__action {
  margin: 10px 0;
  .el-button {
    .wh(80px, 32px) !important;
  }
}
</style>
